import React from "react";
import style from "./JobPosition.module.scss";
import Button from "../../elements/Buttons/Button";
import Text from "../../atoms/Text/Text";
import { ContainerFullCol } from "../../atoms/Containers/Conainers";
import { Link } from "gatsby";

const JobPosition = ({ callback, title, description, to }) => (
  <section>
    <ContainerFullCol>
      <div className={style.wrapper}>
        <div>
          <div className={style.title}>{title}</div>
          <Text>{description}</Text>
        </div>
        <div className={style.button}>
          <Link to={to}>
            <Button black>
              Guarda
          </Button>
          </Link>
        </div>
      </div>
    </ContainerFullCol>
  </section>
);

export default JobPosition;
