import React from "react";
import Page from "../components/Page";
import Button from "../components/elements/Buttons/Button";
import Layout from "../components/Layout";
import Space from "../components/elements/Space/Space";
import FullImageBanner from "../components/components/FullImageBanner/FullImageBanner";
import ContactForm from "../components/components/ContactForm/ContactForm";
import Numbers, {Number} from "../components/components/Numbers/Numbers";
import Job from "../components/components/JobPosition/JobPosition";
import Text from "../components/atoms/Text/Text";
import CentralLayout from "../components/atoms/CentralLayout/CentralLayout";
import {scrollToContact} from "../utility/function";
import GreenTitle from "../components/atoms/GreenTitle/GreenTitle";
import UnderlineTitle from "../components/atoms/UnderlineTitle/UnderlineTitle";
import Testimonial, {TestimonialSlider} from "../components/elements/Testimonial/Testimonial";
import ClientsTestimonialsImages from "../components/components/ClientsTestimonialsImages/ClientsTestimonialsImages";

const Assicurativo = () => (
  <Page title='Lavora con noi' description='Freelance fullstack web developer (mid-level) - possibilità remoto' image='/favicon.png'>
    <Layout navigationCallback={() => scrollToContact()}>
      <Space mobile={15} table={15} desktop={15} />

      <Space mobile={10} table={10} desktop={10} />
      <CentralLayout>
        <GreenTitle caps>Lavora con noi</GreenTitle>
        <h1>Freelance fullstack web developer (mid-level) - possibilità remoto</h1>
      </CentralLayout>

      <Space mobile={10} table={15} desktop={20} />

      <div class='container'>
        <div class='row justify-content-center'>
          <div class='col-md-9 text-justify'>
            <UnderlineTitle>🧙🏻‍♂️ NOI</UnderlineTitle>
            <Text>
              Ciao, siamo E-leads, un marketplace di lead generation in ambito finance.
              <br />
              Cosa significa? Che portiamo ai nostri partner (compagnie assicurative, banche, società finanziarie) nuovi potenziali clienti dal web.
              <br />
              Generiamo i nostri contatti da un vasto network di siti web a noi affiliati, il che ci ha consentito, in poco tempo, di raggiungere volumi di generazione importanti, diventando per i
              nostri clienti un partner di riferimento.
              <br />
              Siamo giovani (nati nel 2018), ma stiamo crescendo velocemente.
              <br />
              <br />
              Ci occupiamo di marketing online, ma il nostro “core” è la tecnologia, rappresentata da una piattaforma proprietaria di lead generation in real-time e una serie di tool a suo supporto.
              <br />
              <br />
              Siamo alla ricerca di un full-stack developer mid-level per la realizzazione di nuovi progetti.
            </Text>
            <Space mobile={4} table={6} desktop={8} />
            <UnderlineTitle>⭐ I NOSTRI VALORI</UnderlineTitle>
            <Text>
              <ul>
                <li>Non abbiamo paura di testare nuove idee, che possono provenire da ogni membro del team.</li>
                <li>Iteriamo velocemente, spingendo sull’acceleratore quando un’idea funziona o buttandola nel cestino quando non lo fa.</li>
                <li>Non abbiamo paura di sbagliare, e soprattutto di imparare dai nostri errori.</li>
                <li>Ci diciamo tutto, sia le cose positive che quelle negative, perché riteniamo che sia l’unico modo per “crescere” insieme.</li>
                <li>Adottiamo metodologie di lavoro Agile.</li>
              </ul>
            </Text>
            <Space mobile={4} table={6} desktop={8} />
            <UnderlineTitle>👨🏻‍💻👩🏻‍💻 TU</UnderlineTitle>
            <Text>
              <ul>
                <li>Hai già sviluppato applicazioni con framework Frontend come Angular, React o simili.</li>
                <li>Sei skillato in Node.js, utilizzando framework come Express o simili.</li>
                <li>Hai già sviluppato REST API, ma trovi che GraphQL sia una figata!</li>
                <li> Sai come strutturare database NoSQL e ne conosci la logica.</li>
                <li>Sai che non basta scrivere del buon codice, ma serve anche documentarlo altrettanto bene.</li>
                <li>Git per te non ha segreti.</li>
                <li>Sei in grado di organizzare il tuo lavoro in autonomia anche da remoto.</li>
                <li>Sei abituato a lavorare in team Agile (Scrum) o ne conosci la teoria.</li>
                <li>Utilizzi quotidianamente strumenti quali Slack, Hangout, etc.</li>
                <li>Sei una persona curiosa, ti piace rimanere aggiornato sulle nuove tecnologie e sperimentarle nei tuoi progetti.</li>
              </ul>
            </Text>
            <Space mobile={4} table={6} desktop={8} />
            <UnderlineTitle>🦸🏻‍♀️🦸🏻‍♂️IL TUO COMPITO</UnderlineTitle>
            <Text>
              Lavorerai fianco a fianco con il nostro CTO, con due obiettivi:
              <ul>
                <li>Apprendere nel dettaglio il funzionamento del nostro software e fare da “backup” in caso di necessità.</li>
                <li>Contribuire direttamente alla progettazione e allo sviluppo dei nuovi prodotti che prevediamo di lanciare nei prossimi 4-6 mesi.</li>
              </ul>
            </Text>
            <Space mobile={4} table={6} desktop={8} />
            <UnderlineTitle>😎 LA NOSTRA OFFERTA</UnderlineTitle>
            <Text>
              La nostra offerta prevede, in questa prima fase, un contratto da freelance. La tariffa oraria sarai tu a proporla, come pensiamo sia più giusto. Quello che noi cerchiamo è una persona
              Affidabile e Smart.
              <br />
              Dopo questo primo periodo di reciproca conoscenza valuteremo insieme, se ti interessano, altre forme di rapporti contrattuali.
              <br />
              Di idee qui ce ne sono tante 😉
            </Text>
            <Text>
              Possibilità di lavoro da remoto o nella nostra sede di Verrone (BI).
              <br />
              <br />
              Accettiamo unicamente candidature personali, no agenzie.
              <br /> Inviaci il tuo profilo Linkedin e il tuo CV a <a href='mailto:jobs@e-leads.it'>jobs@e-leads.it</a>, specificando nell'oggetto "Full-stack web developer".
            </Text>
          </div>
        </div>
      </div>

      <Space mobile={20} table={20} desktop={20} />

      <ContactForm text_line1='Vuoi scambiare due chiacchiere?' text_line2='Contattaci!' />
    </Layout>
  </Page>
);

export default Assicurativo;
